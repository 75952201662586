import * as yup from "yup";
export var UserUpdateModalSchemaForUser = yup.object().shape({
    first_name: yup.string().required("Не должно быть пустым").min(1),
    last_name: yup.string().required("Не должно быть пустым").min(1),
    profession: yup.string().required("Не должно быть пустым").min(1),
    username: yup.string().email().required("Не должно быть пустым").min(1),
    // old_password: yup.string().required("Не должно быть пустым").min(1),
    // new_password: yup.string().required("Не должно быть пустым").min(1),
});
export var UserUpdateModalSchemaForExpert = yup.object().shape({
    first_name: yup.string().required("Не должно быть пустым").min(1),
    last_name: yup.string().required("Не должно быть пустым").min(1),
    profession: yup.string().required("Не должно быть пустым").min(1),
    username: yup.string().email().required("Не должно быть пустым").min(1),
    // old_password: yup.string().required("Не должно быть пустым").min(1),
    // new_password: yup.string().required("Не должно быть пустым").min(1),
});
export var UserUpdateModalSchemaForCompany = yup.object().shape({
    username: yup.string().email().required("Не должно быть пустым").min(1),
    name: yup.string().required("Не должно быть пустым").min(1),
    status: yup.string().required("Не должно быть пустым").min(1).max(20),
    // old_password: yup.string().required("Не должно быть пустым").min(1),
    // new_password: yup.string().required("Не должно быть пустым").min(1),
});
